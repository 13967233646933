import { Config, apiGet, apiPost } from 'src/shared/utils/api-request';

export const getTransactionDataAPI = async () => {
  const config = {} as Config;

  config.custom = {
    setLoading: () => {}
  };
  const res = await apiPost('management/transaction.json', config);

  return res.data;
};

export const getUsersIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/users', data);
  return res;
};

export const getGroupsIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/groups', data);
  return res;
};

export const getRolesIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/roles', data);
  return res;
};

export const getNonHumanIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/access-keys', data);
  return res;
};
