import { useRef, useState } from 'react';
import { Avatar, Box, Button, Hidden, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Typography } from 'src/shared/components/index';
import EmptyUserImage from '../../../assets/images/Operation.png';
import { Signout } from 'src/shared/utils/signout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store/configure-store';
import { clearResults } from 'src/modules/SettingsV2/services/settings.service';
import { getInitials } from 'src/shared/utils/utils';

const HeaderUserbox = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const dispatch = useDispatch<AppDispatch>();
  const user = {
    name: userData.first_name + ' ' + userData.last_name,
    jobtitle: userData.enterprise_name
  };

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const userLogout = () => {
    Signout();
    dispatch(clearResults());
  };

  return (
    <>
      <Button color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="circular"
          className="primary-background"
          alt={user.name}
        >
          {getInitials(user?.name)}
        </Avatar>
        <Hidden mdDown>
          <Box className="UserBoxText">
            <Typography className="UserBoxLabel" variant="body1">
              {user.name}
            </Typography>
            <Typography className="UserBoxDescription" variant="body2">
              {user.jobtitle}
            </Typography>
          </Box>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </Button>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className="m-width-Userbox MenuUserBox">
          <Avatar
            variant="circular"
            alt={user.name}
            className="primary-background"
          >
            {getInitials(user?.name)}
          </Avatar>
          <Box className="UserBoxText">
            <Typography className="UserBoxLabel" variant="body1">
              {user.name}
            </Typography>
            <Typography className="UserBoxDescription" variant="body2">
              {user.jobtitle}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ m: 1 }}>
          {/* <Button color="primary" fullWidth onClick={userLogout}> */}
          <Button color="primary" fullWidth onClick={userLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserbox;
