import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../models';
import {
  getIdentityFingerprintAPI,
  getIdentityRawDataAPI,
  getIdentityUsageAPI
} from '../apis/IdentityFingerprintApi';
import {
  getRolesIdentityAPI,
  getUsersIdentityAPI
} from 'src/modules/IdentityInventory/apis/IdentityInventoryAPI';
import { getCommandOutputAPI } from 'src/modules/Investigation/apis/ForensicApi';
import dayjs from 'dayjs';
import { INPUT_DATE_TIME_FORMAT } from 'src/shared/constants/constants';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}
interface TransactionState {
  iamUsers: [];
  loadingIamUsers: boolean;
  iamUsersLastEvaluatedKey: LastEvaluatedKey | null;
  iamRoles: [];
  loadingIamRoles: boolean;
  iamRolesLastEvaluatedKey: LastEvaluatedKey | null;
  identityFingerprintData: {};
  loadingIdentityFingerprintData: boolean;
  component: string;
  commandExecuted: string;
  selectedIdentityType: string;
  selectedAccountId: [];
  selectedTime: number;
  selectedServiceType: string;
  selectedResourceId: string;
  selectedIdentityInfo: {};
  selectedRawDataFilterKey: string;
  selectedRawDataFilterValue: string;
  rawData: {};
  loadingRawData: boolean;
  geoLocationRawData: {};
  loadingGeoLocationRawData: boolean;
  showBackButton: boolean;
  drawerData: {};
  filterByAccountID: string;
  identityUsage: {};
  loadingIdentityUsage: boolean;
  openViewUsageDialog: boolean;
}
const initialState: TransactionState = {
  // const initialState = {
  iamUsers: [],
  loadingIamUsers: false,
  iamUsersLastEvaluatedKey: null,
  iamRoles: [],
  loadingIamRoles: false,
  iamRolesLastEvaluatedKey: null,
  identityFingerprintData: [],
  loadingIdentityFingerprintData: false,
  component: 'history',
  commandExecuted: '',
  selectedIdentityType: 'Users',
  selectedAccountId: [],
  selectedTime: 1,
  selectedIdentityInfo: {},
  selectedServiceType: '',
  selectedResourceId: '',
  selectedRawDataFilterKey: '',
  selectedRawDataFilterValue: '',
  rawData: {},
  loadingRawData: false,
  geoLocationRawData: {},
  loadingGeoLocationRawData: false,
  showBackButton: false,
  drawerData: {},
  filterByAccountID: '',
  // selectedResourceType: ''
  identityUsage: {},
  loadingIdentityUsage: false,
  openViewUsageDialog: false
};

export const getIdentityFingerprint = createAsyncThunk(
  'identityFingerprint/getIdentityFingerprint',
  async (data: any) => {
    const response = await getIdentityFingerprintAPI(data);
    return response?.data?.identity_fingerprint_data;
  }
);
export const getRoles = createAsyncThunk(
  'identityFingerprint/getRoles',
  async (data: any) => {
    const response = await getRolesIdentityAPI(data);
    return response.data;
  }
);

export const getUsers = createAsyncThunk(
  'identityFingerprint/getUsers',
  async (data: any) => {
    const response = await getUsersIdentityAPI(data);
    return response.data;
  }
);

export const getRawData = createAsyncThunk(
  'identityFingerprint/getRawData',
  async (data: any) => {
    const response = await getIdentityRawDataAPI(data);
    return response?.data;
  }
);

export const getGeoLocationRawData = createAsyncThunk(
  'identityFingerprint/getGeoLocationRawData',
  async (data: any) => {
    const response = await getIdentityRawDataAPI(data);
    return response?.data;
  }
);

export const getIdentityUsage = createAsyncThunk(
  'identityFingerprint/getIdentityUsage',
  async (data: any) => {
    const response = await getIdentityUsageAPI(data);
    return response?.data;
  }
);

export const identityFingerprintSlice = createSlice({
  name: 'identityFingerprint',
  initialState,
  reducers: {
    setSelectedIdentityType: (state, action) => {
      state.selectedIdentityType = action.payload;
    },
    setSelectedAccountId: (state, action) => {
      state.selectedAccountId = action.payload;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    setSelectedIdentityInfo: (state, action) => {
      state.selectedIdentityInfo = action.payload;
    },
    setSelectedServiceType: (state, action) => {
      state.selectedServiceType = action.payload;
    },
    setSelectedResourceId: (state, action) => {
      state.selectedResourceId = action.payload;
    },
    clearAllIdentityFingerprintData: (state) => {
      state.identityFingerprintData = [];
      state.loadingIdentityFingerprintData = false;
      state.component = 'history';
      state.commandExecuted = '';
      state.selectedIdentityType = 'Users';
      state.selectedAccountId = [];
      state.selectedTime = 1;
      state.selectedIdentityInfo = {};
      state.selectedServiceType = '';
      state.selectedResourceId = '';
      state.rawData = [];
      state.loadingRawData = false;
      state.geoLocationRawData = [];
      state.loadingGeoLocationRawData = false;
      state.filterByAccountID = '';
    },
    clearRawData: (state) => {
      state.rawData = [];
      state.loadingRawData = false;
    },
    clearGeoLocationRawData: (state) => {
      state.geoLocationRawData = [];
      state.loadingGeoLocationRawData = false;
    },
    setShowBackButton: (state, action) => {
      state.showBackButton = action.payload;
    },
    setDrawerData: (state, action) => {
      state.drawerData = action.payload;
    },
    setFilterByAccountID: (state, action) => {
      state.filterByAccountID = action.payload;
    },
    setOpenViewUsageDialog: (state, action) => {
      state.openViewUsageDialog = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      // getUsers
      .addCase(getUsers.pending, (state) => {
        state.loadingIamUsers = true;
      })
      .addCase(getUsers.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loadingIamUsers = false;
        if (action.payload) {
          state.iamUsersLastEvaluatedKey = action.payload.last_evaluated_key;
          state.iamUsers = [...state.iamUsers, ...action.payload.items];
        }
      })
      .addCase(getUsers.rejected, (state) => {
        state.loadingIamUsers = false;
      })
      // getRoles
      .addCase(getRoles.pending, (state) => {
        state.loadingIamRoles = true;
      })
      .addCase(getRoles.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loadingIamRoles = false;
        if (action.payload) {
          state.iamRolesLastEvaluatedKey = action.payload.last_evaluated_key;
          let data = action.payload.items;
          data = data.map((d) => {
            let dataObj = { id: d.breez_event_id, ...d };
            if (d?.Name) {
              dataObj['RoleName'] = d?.Name;
            }
            return dataObj;
          });
          state.iamRoles = [...state.iamRoles, ...data];
        }
      })
      .addCase(getRoles.rejected, (state) => {
        state.loadingIamRoles = false;
      })
      // getIdentityFingerprint
      .addCase(getIdentityFingerprint.pending, (state) => {
        state.loadingIdentityFingerprintData = true;
      })
      .addCase(
        getIdentityFingerprint.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingIdentityFingerprintData = false;
          if (action.payload) {
            state.identityFingerprintData = {
              [state.selectedIdentityType]: {
                [state.selectedResourceId]: {
                  [state.selectedTime]: action.payload
                }
              }
            };
          }
        }
      )
      .addCase(getIdentityFingerprint.rejected, (state) => {
        state.loadingIdentityFingerprintData = false;
      })
      // getRawData
      .addCase(getRawData.pending, (state) => {
        state.loadingRawData = true;
      })
      .addCase(
        getRawData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingRawData = false;
          if (action?.payload) {
            state.rawData = setRawData(action, state, 'rawData');
          }
        }
      )
      .addCase(getRawData.rejected, (state) => {
        state.loadingRawData = false;
      })
      // getGeoLocationRawData
      .addCase(getGeoLocationRawData.pending, (state) => {
        state.loadingGeoLocationRawData = true;
      })
      .addCase(
        getGeoLocationRawData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingGeoLocationRawData = false;
          if (action?.payload) {
            state.geoLocationRawData = setRawData(
              action,
              state,
              'geoLocationRawData'
            );
          }
        }
      )
      .addCase(getGeoLocationRawData.rejected, (state) => {
        state.loadingGeoLocationRawData = false;
      })
      //getIdentityUsage
      .addCase(getIdentityUsage.pending, (state) => {
        state.loadingIdentityUsage = true;
      })
      .addCase(
        getIdentityUsage.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingIdentityUsage = false;
          if (action?.payload) {
            state.identityUsage = action?.payload;
          }
        }
      )
      .addCase(getIdentityUsage.rejected, (state) => {
        state.loadingIdentityUsage = false;
      });
  }
});

const setRawData = (action, state, rawDataStateKey) => {
  const identityType = state?.selectedIdentityType;
  const resourceId = state?.selectedResourceId;
  const time = state?.selectedTime;
  const queryParams = action?.meta?.arg?.query_params;
  const filterKey = queryParams?.filter_key;
  const filterValue = queryParams?.filter_value;

  const prevState =
    state?.[rawDataStateKey]?.[identityType]?.[resourceId]?.[time]?.[
      filterKey
    ]?.[filterValue]?.['data'];

  const sortedData = sortRawData(
    prevState
      ? [...prevState, ...action?.payload?.items]
      : action?.payload?.items
  );
  return {
    [identityType]: {
      [resourceId]: {
        [time]: {
          [filterKey]: {
            [filterValue]: {
              data: sortedData,
              lastEvaluatedKey: action?.payload?.last_evaluated_key,
              count: sortedData?.count,
              totalCount: action?.payload?.total_count
            }
          }
        }
      }
    }
  };
};

const sortRawData = (dataList) => {
  let sortedData = dataList?.sort(function (a, b) {
    return (
      dayjs(b.breez_timestamp, INPUT_DATE_TIME_FORMAT).date() -
      dayjs(a.breez_timestamp, INPUT_DATE_TIME_FORMAT).date()
    );
  });
  sortedData = sortedData?.map((d) => {
    if (d.breez_identity_type === 'machine') {
      return {
        ...d,
        principal_type: d?.principal_arn,
        principal_arn: d?.session_name
      };
    } else {
      return d;
    }
    return d;
  });
  return sortedData;
};
export const {
  setSelectedIdentityType,
  setSelectedAccountId,
  setSelectedTime,
  setSelectedIdentityInfo,
  setSelectedServiceType,
  setSelectedResourceId,
  clearAllIdentityFingerprintData,
  clearRawData,
  clearGeoLocationRawData,
  setShowBackButton,
  setDrawerData,
  setFilterByAccountID,
  setOpenViewUsageDialog
} = identityFingerprintSlice.actions;

export default identityFingerprintSlice.reducer;
