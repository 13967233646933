import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import TextField from '../textfield';
import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Container,
  InputAdornment,
  useTheme,
  ButtonProps,
  IconButton,
  Tooltip
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RefreshIcon from '@mui/icons-material/Refresh';

interface TableComponentProps {
  rows: any[];
  columns: GridColDef[];
  onCellClick?: (params: any, event?: any) => void;
  loading: boolean;
  page: number;
  pageSize: number;
  onPaginationModelChange?: (params1: any, params2: any) => void;
  rowCount: number;
  FilterComponent?: any;
  TableHeaderRight?: any;
  showSearchOption?: boolean;
  handleRefreshBtnClick?: () => void;
}
export const Tables = ({
  rows,
  columns,
  onCellClick,
  loading,
  page,
  pageSize,
  onPaginationModelChange,
  rowCount,
  FilterComponent,
  TableHeaderRight,
  showSearchOption = true,
  handleRefreshBtnClick
}: TableComponentProps) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    datatable: {
      '&.MuiDataGrid-root': {
        border: 'none'
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none !important'
      },
      '&.MuiDataGrid-root .MuiDataGrid-withBorderColor': {
        border: 'none'
      }

      // marginLeft: '20px'
      // marginRight: '20px',
    },
    datatableHeader: {
      '&.MuiDataGrid-columnHeadersInner': {
        borderBottom: `5px solid ${theme.colors.secondary.light}`
        // backgroundColor: theme.colors.primary.main
      }
    },
    datatableCellCOntent: {
      '&.MuiDataGrid-cellContent': {
        color: theme.colors.secondary.main,
        fontWeight: 400
      }
    },
    datatableEvenRow: {
      backgroundColor: theme.colors.secondary.lighter
    }
  });
  const classes = useStyles();

  const [tableData, setTableData] = useState([...rows]);

  useEffect(() => {
    setTableData([...rows]);
  }, [rows]);

  const handleSeachTextFieldChange = (searchValue: string) => {
    const filteredRows = [...rows].filter((obj) =>
      Object.values(obj).some((val) =>
        val?.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setTableData(filteredRows);
  };

  return (
    <Box pb={3}>
      {showSearchOption && (
        <Box>
          <Grid
            container
            direction="row"
            spacing={2}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid
              item
              xs={4}
              // xs={12}
              // md={10}
              // sm={10}
              // lg={12}
              display={'flex'}
              justifyContent={'flex-start'}
            >
              <Box>
                <TextField
                  id="outlined-search"
                  placeholder="Search"
                  type="text"
                  fullWidth
                  onChange={(e) => handleSeachTextFieldChange(e.target.value)}
                  InputProps={{
                    sx: { borderRadius: '4px', padding: '12px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchRoundedIcon
                          sx={{ fontSize: 18 }}
                          color="primary"
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Box alignSelf={'center'} pl={1}>
                {FilterComponent}
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              pr={1}
            >
              {TableHeaderRight}
              <Tooltip title={'Refresh'} sx={{ ml: 2 }}>
                <IconButton onClick={handleRefreshBtnClick}>
                  <RefreshIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box className="tablestyle">
        <DataGrid
          rows={tableData}
          columns={columns}
          // checkboxSelection
          // disableColumnSelector={false}
          // disableColumnFilter={true}
          pageSizeOptions={[10, 25, 50, 100]}
          // disableColumnMenu
          autoHeight={true}
          disableRowSelectionOnClick={true}
          disableDensitySelector={true}
          classes={{
            root: classes.datatable,
            cellContent: classes.datatableCellCOntent,
            columnHeadersInner: classes.datatableHeader
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? 'Mui-odd'
              : classes.datatableEvenRow
          }
          onCellClick={onCellClick}
          loading={loading}
          paginationMode="server"
          paginationModel={{
            page,
            pageSize
          }}
          onPaginationModelChange={onPaginationModelChange}
          rowCount={rowCount}
          pagination={true}
        />
      </Box>
    </Box>
  );
};
