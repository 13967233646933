import { SidebarMenus } from 'src/shared/components/index';
import GridViewIcon from '@mui/icons-material/GridView';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import KeyIcon from '@mui/icons-material/Key';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import UpdateIcon from '@mui/icons-material/Update';
import { DEPLOYMENT_ENV } from 'src/shared/constants/constants';
import { useEffect, useState } from 'react';
import { sidebarprop } from 'src/shared/components/sidebar-menu-structure';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/configure-store';
import { Box } from '@mui/material';
import usageIcon from 'src/assets/icons/user-based-entitlement.svg';
import integrateIcon from 'src/assets/icons/git-pull-request.svg';

const SidebarMenu = () => {
  const [options, setOptions] = useState([] as sidebarprop[]);
  const userInfo = localStorage.getItem('userData');
  const { userData } = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    const data = userData?.email ? userData : JSON.parse(userInfo);
    let optionsList = [
      {
        has_access: true,
        pathname: 'dashboard',
        icon: <GridViewIcon />,
        submenuname: 'Dashboard'
      },
      {
        has_access: true,
        pathname: 'identity-inventory',
        icon: <CreditCardOutlinedIcon />,
        submenuname: 'Identity Inventory'
      },
      {
        has_access: true,
        pathname: 'runtime',
        icon: <DesktopWindowsOutlinedIcon />,
        submenuname: 'Runtime'
      },
      {
        has_access: true,
        pathname: 'identity-fingerprint',
        icon: <KeyIcon />,
        submenuname: 'Identity Fingerprint'
      },
      {
        has_access: true,
        pathname: 'usage-entitlement',
        icon: (
          <Box width={24} height={24}>
            <img src={usageIcon} alt="ube" width={22} height={22} />
          </Box>
        ),
        submenuname: 'Usage Based Entitlement'
      },
      {
        has_access: true,
        pathname: 'rules',
        icon: <DescriptionOutlinedIcon />,
        submenuname: 'Rules'
      },
      {
        has_access: true,
        pathname: 'alerts',
        icon: <WarningAmberRoundedIcon />,
        submenuname: 'Alerts'
      },
      // {
      //   has_access: true,
      //   pathname: 'actions',
      //   icon: <SplitscreenIcon />,
      //   submenuname: 'Actions'
      // },
      {
        has_access: true,
        pathname: 'investigation',
        icon: <CommitOutlinedIcon />,
        submenuname: 'Investigation'
      },
      {
        has_access: ['DEV', 'DEMO'].includes(DEPLOYMENT_ENV),
        pathname: 'integrations',
        icon: (
          <Box width={24} height={24}>
            <img src={integrateIcon} alt="int" width={22} height={22} />
          </Box>
        ),
        submenuname: 'Integrations'
      },
      // {
      //   has_access: true,
      //   pathname: 'change-management',
      //   icon: <FilterAltIcon />,
      //   submenuname: 'Change Management'
      // },
      {
        has_access:
          data?.roles?.length && ['DEV', 'DEMO'].includes(DEPLOYMENT_ENV)
            ? data?.roles?.includes('admin')
            : false,
        pathname: 'activity-logs',
        icon: <RestoreIcon />,
        submenuname: 'Audit Logs'
      }
    ];
    if (['DEMO'].includes(DEPLOYMENT_ENV))
      optionsList.splice(5, 0, {
        has_access: true,
        pathname: 'just-in-time',
        icon: <UpdateIcon />,
        submenuname: 'Just-In-Time'
      });

    setOptions(optionsList);
  }, [userData]);

  return <SidebarMenus menuname={''} option={options} />;
};

export default SidebarMenu;
