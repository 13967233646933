import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout';
import BaseLayout from 'src/core';

import { GuardedRoute } from './guarded-routes';
import {
  LOGIN,
  DASHBOARD,
  IDENTITY_INVENTORY,
  RUNTIME,
  INVESTIGATION,
  POLICIES,
  AWS_ALERTS,
  IDENTITY_FINGERPRINT,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SETTINGS,
  ORGANIZATION_MANAGEMENT,
  CHANGE_PASSWORD,
  PRIVILEGE_MANAGEMENT,
  PROFILE_MANAGEMENT,
  ALERT_DETAILS,
  ACTIVITY_LOGS,
  INTEGRATIONS
} from './../shared/constants/constants';
import { SuspenseLoader } from 'src/shared/components/index';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(lazy(() => LOGIN));
const Register = Loader(lazy(() => REGISTER));
const ForgotPassword = Loader(lazy(() => FORGOT_PASSWORD));
const ResetPassword = Loader(lazy(() => RESET_PASSWORD));

// Dashboards

const Dashboard = Loader(lazy(() => DASHBOARD));
const IdentityInventory = Loader(lazy(() => IDENTITY_INVENTORY));
const Runtime = Loader(lazy(() => RUNTIME));
const Investigation = Loader(lazy(() => INVESTIGATION));
const Policies = Loader(lazy(() => POLICIES));
const AwsAlerts = Loader(lazy(() => AWS_ALERTS));
const AlertDetails = Loader(lazy(() => ALERT_DETAILS));
const IdentityFingerprit = Loader(lazy(() => IDENTITY_FINGERPRINT));
const Settings = Loader(lazy(() => SETTINGS));
const ActivityLogs = Loader(lazy(() => ACTIVITY_LOGS));
const ProfileManagement = Loader(lazy(() => PROFILE_MANAGEMENT));
const OrganizationManagement = Loader(lazy(() => ORGANIZATION_MANAGEMENT));
const ChangePassword = Loader(lazy(() => CHANGE_PASSWORD));
const PrivilegeManagement = Loader(lazy(() => PRIVILEGE_MANAGEMENT));
const Integrations = Loader(lazy(() => INTEGRATIONS));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/register-bs-as',
        element: <Register />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/reset-password',
        element: <ResetPassword />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <GuardedRoute component={Dashboard} />
      },
      {
        path: 'identity-inventory',
        element: <GuardedRoute component={IdentityInventory} />
      },
      {
        path: 'runtime',
        element: <GuardedRoute component={Runtime} />
      },
      {
        path: 'investigation',
        element: <GuardedRoute component={Investigation} />
      },
      {
        path: 'rules',
        element: <GuardedRoute component={Policies} />
      },
      {
        path: 'alerts',
        element: <GuardedRoute component={AwsAlerts} />
      },
      {
        path: 'alert-details',
        element: <GuardedRoute component={AlertDetails} />
      },
      {
        path: 'alert-details/:alertId',
        element: <GuardedRoute component={AlertDetails} />
      },
      {
        path: 'identity-fingerprint',
        element: <GuardedRoute component={IdentityFingerprit} />
      },
      {
        path: 'usage-entitlement',
        element: <GuardedRoute component={IdentityFingerprit} />
      },
      {
        path: 'integrations',
        element: <GuardedRoute component={Integrations} />
      },
      {
        path: 'settings',
        element: <GuardedRoute component={Settings} />,
        children: [
          {
            path: 'profile-management',
            element: <GuardedRoute component={ProfileManagement} />
          },
          {
            path: 'organization-management',
            element: <GuardedRoute component={OrganizationManagement} />
          },
          {
            path: 'change-password',
            element: <GuardedRoute component={ChangePassword} />
          }
        ]
      },
      {
        path: 'just-in-time',
        element: <GuardedRoute component={PrivilegeManagement} />
      },
      {
        path: 'activity-logs',
        element: <GuardedRoute component={ActivityLogs} />
      }
    ]
  }
];

export default routes;
