import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommonServiceState {
  tab: any;
  dataSource: string;
}
const initialState: CommonServiceState = {
  tab: null,
  dataSource: 'AWS'
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTab: (state: any, action: PayloadAction<any>) => {
      state.tab = action.payload;
    },
    setDataSource: (state: any, action: PayloadAction<any>) => {
      state.dataSource = action.payload;
    }
  }
});
export const { setTab, setDataSource } = commonSlice.actions;
export default commonSlice.reducer;
