import { Config, apiGet, apiPost, apiPut } from 'src/shared/utils/api-request';

export const getAllRemediationsAPI = async (data: any) => {
  const res = await apiPost('alerts', data);
  return res;
};

export const getAllClosedAlertsAPI = async (data: any) => {
  const res = await apiPost('alerts', data);
  return res;
};
