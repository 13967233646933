import {
  getGeoLocation,
  getNameFromArn,
  parseAndFormatDateTime
} from 'src/shared/utils/common-functions';
import { removeUnderscore, titleCase } from 'src/shared/utils/utils';

export const timelineDataMapping = (data) => {
  let mapping = [
    {
      label: 'API Name',
      value: data?.api_name,
      has_timeline: false
    },
    {
      label: 'API Status',
      value: data?.api_status,
      has_timeline: false
    },
    {
      label: 'Identity Type',
      value: data?.identity_type,
      has_timeline: false
    },
    {
      label: 'Role',
      value: data?.role_arn,
      has_timeline: false
    },
    {
      label: 'Resource',
      value: data?.resource,
      has_timeline: false
    },
    {
      label: 'User',
      value:
        data?.breez_identity_type === 'machine' &&
        data?.api_name === 'AssumeRole'
          ? data?.principal_type
          : getNameFromArn(data?.principal_arn),
      has_timeline: false
    },
    {
      label: 'Region',
      value: data?.cloud_region,
      has_timeline: false
    },
    {
      label: 'Cloud Account ID',
      value: data?.cloud_account_id,
      has_timeline: false
    },
    {
      label: 'IP Address',
      value: data?.source_ip_address,
      has_timeline: false
    },
    {
      label: 'Geo Location',
      value: getGeoLocation(data?.geo_data),
      has_timeline: false
    },
    {
      label: 'Access Key',
      value: data?.access_key_id,
      has_timeline: false
    }
  ];

  if (data?.breez_identity_type === 'machine') {
    mapping.splice(2, 0, {
      label: 'Principal Type',
      value: data?.principal_type,
      has_timeline: false
    });
  }

  if (data?.api_status === 'Failure') {
    mapping = mapping.concat([
      {
        label: 'Error Code',
        value: data?.error_code,
        has_timeline: false
      },
      {
        label: 'Error Message',
        value: data?.error_message,
        has_timeline: false
      }
    ]);
  }

  if (
    !data?.breez_identity_type ||
    (data?.breez_identity_type && data?.breez_identity_type === 'code')
  ) {
    mapping = mapping.concat([
      {
        label: 'Git User',
        value: data?.git_last_modified_by,
        has_timeline: false
      },
      {
        label: 'Git Commit',
        value: data?.git_commit,
        has_timeline: false
      },
      {
        label: 'Git Organization',
        value: data?.git_org,
        has_timeline: false
      },
      {
        label: 'Git Repository',
        value: data?.git_repo,
        has_timeline: false
      },
      {
        label: 'Git File',
        value: data?.git_file,
        has_timeline: false
      }
    ]);
  }
  if (data?.role_chain) {
    mapping = mapping.concat([
      {
        label: 'Role Chain',
        value: data?.role_chain,
        has_timeline: true
      }
    ]);
  }
  return mapping;
};

export const oktaTimelineDataMapping = (data) => {
  let mapping = [
    {
      label: 'API Name',
      value: data?.api_name
    },
    {
      label: 'API Status',
      value: data?.api_status
    },
    {
      label: 'Resource Type',
      value: data?.resource_type
    },
    {
      label: 'Source',
      value: data?.source
    },
    {
      label: 'Threat',
      value: data?.threat?.toString()?.toUpperCase()
    },
    {
      label: 'Resource',
      value: data?.resource
    },
    {
      label: 'User',
      value:
        data?.breez_identity_type === 'machine' &&
        data?.api_name === 'AssumeRole'
          ? data?.principal_type
          : getNameFromArn(data?.principal_arn)
    },
    {
      label: 'IP Address',
      value: data?.source_ip_address
    },
    {
      label: 'Geo Location',
      value: getGeoLocation(data?.geo_data)
    },
    {
      label: 'IP Chain',
      value: data?.ip_chain?.map((item) => item?.ip)?.toString()
    }
  ];
  return mapping;
};

export const vpcTimelineDataMapping = (data) => {
  let mapping = [
    {
      label: 'VPC ID',
      value: data?.vpc_id
    },
    {
      label: 'Instance ID',
      value: data?.instance_id
    },
    {
      label: 'Interface ID',
      value: data?.interface_id
    },
    {
      label: 'Source Port',
      value: data?.source_port
    },
    {
      label: 'Source IP Address',
      value: data?.source_ip_address
    },
    {
      label: 'Destination Port',
      value: data?.destination_port
    },
    {
      label: 'Destination IP Address',
      value: data?.destination_ip_address
    },
    {
      label: 'Flow Direction',
      value: data?.flow_direction
    },
    {
      label: 'Action',
      value: data?.action
    },
    {
      label: 'Cloud Account ID',
      value: data?.cloud_account_id
    },
    {
      label: 'Cloud Region',
      value: data?.cloud_region
    },
    {
      label: 'Geo Location',
      value: getGeoLocation(data?.geo_data)
    },
    {
      label: 'Traffic Type',
      value: data?.traffic_type
    },
    {
      label: 'Protocol',
      value: data?.type
    },
    {
      label: 'Log Type',
      value: data?.log_type
    },
    {
      label: 'Size',
      value: `${data?.bytes} bytes`
    }
  ];
  return mapping;
};

export const gcpTimelineDataMapping = (data) => {
  let mapping = [
    {
      label: 'API Status',
      value: data?.api_status
    },
    {
      label: 'Breez Identity Type',
      value: titleCase(data?.breez_identity_type)
    },
    {
      label: 'Principal',
      value: data?.principal_arn
    },
    {
      label: 'Role',
      value: data?.role_arn
    },
    {
      label: 'Session',
      value: data?.session_name
    },
    {
      label: 'Source IP Address',
      value: data?.source_ip_address
    },
    {
      label: 'Resource',
      value: data?.resource
    },
    {
      label: 'Permission Type',
      value: removeUnderscore(data?.permission_type)
    },
    {
      label: 'Cloud Account ID',
      value: data?.cloud_account_id
    },
    {
      label: 'Cloud Region',
      value: data?.cloud_region
    },
    {
      label: 'Geo Location',
      value: getGeoLocation(data?.geo_data)
    },
    {
      label: 'Severity',
      value: data?.severity
    },
    {
      label: 'Event Source',
      value: data?.event_source
    },
    {
      label: 'Event Time',
      value: parseAndFormatDateTime(data?.event_time)
    }
  ];
  return mapping;
};

export const azureTimelineDataMapping = (data) => {
  let mapping = [
    {
      label: 'API Name',
      value: data?.api_name
    },
    {
      label: 'API Status',
      value: data?.api_status
    },
    {
      label: 'Bucket Name',
      value: data?.bucket_name
    },
    {
      label: 'Breez Identity Type',
      value: titleCase(data?.breez_identity_type)
    },
    {
      label: 'Principal',
      value: data?.principal_arn
    },
    {
      label: 'Session',
      value: data?.session_name
    },
    {
      label: 'Operation',
      value: data?.operation
    },
    {
      label: 'Source IP Address',
      value: data?.source_ip_address
    },
    {
      label: 'Resource',
      value: data?.resource
    },
    {
      label: 'Cloud Account ID',
      value: data?.cloud_account_id
    },
    {
      label: 'Geo Location',
      value: getGeoLocation(data?.geo_data)
    },
    {
      label: 'Event Source',
      value: data?.event_source
    },
    {
      label: 'Event Time',
      value: parseAndFormatDateTime(data?.event_time)
    }
  ];
  return mapping;
};
