import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllClosedAlertsAPI } from '../apis/actionApi';
import { showSuccessMessage } from '../../../shared/components/index';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface ActionState {
  closedAlerts: string[];
  loadingClosedAlerts: boolean;
  currentPage: number;
  totalCount: number;
  lastEvaluatedKey: LastEvaluatedKey;
}
const initialState: ActionState = {
  closedAlerts: [],
  loadingClosedAlerts: false,
  lastEvaluatedKey: null,
  currentPage: 0,
  totalCount: 0
};

export const getAllClosedAlerts = createAsyncThunk(
  'closedAlerts/getAllClosedAlerts',
  async (data: any) => {
    const response = await getAllClosedAlertsAPI(data);
    return response.data;
  }
);

export const ClosedAlertsSlice = createSlice({
  name: 'ClosedAlerts',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setPolicies: (state: any, action: PayloadAction<any>) => {
      state.closedAlerts[state.currentPage][action.payload.id] = action.payload;
    },
    clearData: (state: any) => {
      state.closedAlerts = [];
      state.lastEvaluatedKey = null;
      state.currentPage = 0;
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllClosedAlerts.pending, (state) => {
        state.loadingClosedAlerts = true;
      })
      .addCase(
        getAllClosedAlerts.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingClosedAlerts = false;

          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.closedAlerts = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getAllClosedAlerts.rejected, (state) => {
        state.loadingClosedAlerts = false;
      });
  }
});
export const { setCurrentPage, setPolicies, clearData } =
  ClosedAlertsSlice.actions;
export default ClosedAlertsSlice.reducer;
